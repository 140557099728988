import axios from '@axios'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchEmail(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${useAppConfig().AppUrl}mst_email/view`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
